import React from "react"
import styled from "@emotion/styled"
import { color, fontSize } from "styled-system"

const StyledWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1120px;
  overflow-x: hidden;
  margin: 0 auto;
  ${color}
  ${fontSize}
`

class Wrapper extends React.Component {
  render() {
    const { children } = this.props
    return <StyledWrapper>{children}</StyledWrapper>
  }
}

export default Wrapper
