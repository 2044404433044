import React from "react"
import { Link, graphql } from "gatsby"
import ProgressBar from "react-customizable-progressbar"
import moment from "moment"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Wrapper from "../components/blog-wrapper"

class Runs extends React.Component {
  render() {
    const runs = this.props.data.allGoogleSheetRunkeeperRow.nodes
    const { site } = this.props.data
    let mileageTotal = 0
    runs.map(run => (mileageTotal += run.miles))
    mileageTotal = Math.round(mileageTotal)

    const sortedRuns = runs.sort((a, b) => {
      const aDate = moment(a.date)
      const bDate = moment(b.date)
      if (aDate < bDate) return 1
      else if (aDate > bDate) return -1
      return 0
    })

    const Indicator = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      font-size: 1em;
      font-weight: 100;
      color: #555;
      user-select: none;
    `

    const postSlug = run => {
      const formattedDate = moment(run.date).format("YYYY-MM-DD-hh-a")

      return (
        <div key={run.id}>
          <h3>
            <Link
              style={{ boxShadow: `none` }}
              to={`blog/miles/${formattedDate}`}
            >
              {run.miles}
            </Link>
          </h3>
          <small>{run.date}</small>
        </div>
      )
    }
    return (
      <Layout location={`blog/miles`} title={site.siteMetadata.title}>
        <SEO title="Attempts at running" />
        <Wrapper>
          <div style={{ margin: "20px 0 40px" }}>
            <div>
              <h3 style={{ textAlign: "center" }}>
                {mileageTotal} miles run out of 400
              </h3>
              <div
                style={{
                  justifyContent: "center",
                }}
              >
                <ProgressBar
                  progress={(mileageTotal / 400) * 100}
                  radius={80}
                  trackStrokeColor="gray"
                  trackStrokeWidth={30}
                  strokeWidth={30}
                  strokeColor="green"
                  strokeLinecap="butt"
                  initialAnimation={true}
                  initialAnimationDelay={400}
                >
                  <div className="indicator">
                    <Indicator>
                      {Math.floor((mileageTotal / 400) * 100)}%
                    </Indicator>
                  </div>
                </ProgressBar>
              </div>
            </div>
            {sortedRuns.map(run => postSlug(run))}
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

export default Runs

export const pageQuery = graphql`
  query RunsQuery {
    allGoogleSheetRunkeeperRow(sort: { fields: [date], order: ASC }) {
      nodes {
        id
        miles
        date
        duration
        publicurl
      }
    }
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`
